import React from 'react'
import image from '../assets/BandF.jpg'
import Reveal from '../utils/Reveal'

const OurVision = () => {
  return (
    <div className=''>
    <div className="flex items-center justify-center text-black text-5xl ">
  <Reveal>
    <h1 className="text-center">
      The<span className="text-indigo-600"> Vision</span>
    </h1>
  </Reveal>
</div>

    
        <div className='w-full bg-white py-4 px-4 flex-row'>
    <div className='max-w-[1240px]  mx-auto grid lg:grid-cols-2'>
     <div className='object-cover' >
     <Reveal>
    <img className=' aspect-square object-cover mx-auto rounded-2xl ' src={image} alt='/' />
    </Reveal>

      </div>

      <div className='p-4  items-center justify-center px-4   sm:text-2xl text-1xl '>
      <div className='py-4'>
      <Reveal>
      <p className='font-roboto '><span className=''>Blaise Nsenguwera | Co-Founder: </span> “When we started this journey, our whole mission was to create a platform where up and coming brands can be seen and receive the recognition they deserve.”</p>
      </Reveal>
        
      </div>
      <div className='py-4'>
      <Reveal>
      <p className='font-roboto '><span className=''>Felix Senga | Co-Founder: </span> “Our focus has always been about how we can improve the customer experience and encourage collaboration within our industries. We hope that FLY can offer something different to the public and act as a catalyst for people who want to chase their creative dreams and showcase their talent.”</p>
      </Reveal>
        
      </div>
      </div>
    </div>
    {/* <hr
  class=" flex my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" /> */}
  </div>
</div>
  )
}

export default OurVision