import React, {useState} from 'react'
import {connect, useDispatch} from "react-redux";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {submitQuery} from "../redux/reducers/contactSlice";
import * as actions from "../redux/thunks/contactFormThunk";

const Interested = ({contactFormThunk}) => {

    const [submitted, setSubmitted] = useState(false);

    const dispatch = useDispatch();

    if (submitted){
        return (
            <section className="bg-black ">
                <div className="py-8 lg:py-16 px-4 mx-auto my-auto max-w-screen-md">
                    <h2 className="mb-4 text-7xl tracking-tight text-center text-indigo-600 dark:text-white">Thank You for Your Interest in FLY</h2>
                    <p className="mb-8 lg:mb-16 font-light text-center text-white dark:text-gray-400 sm:text-xl">We look forward to working with you.</p>
                </div>
            </section>
        );
    }

  return (
      <Formik
            initialValues={{
                email: "",
                message: ""
            }}
            validationSchema={Yup.object({
                email: Yup.string().email().required("You must provide a valid email address."),
                message: Yup.string().min(1, "Message cannot be empty").required()
            })}
            onSubmit={(values) => {
                dispatch(submitQuery(values));
                contactFormThunk();
                setSubmitted(true);
            }}>
          {({isSubmitting}) => (
              <section className="bg-black ">
                  <div className="py-8 lg:py-16 px-4 mx-auto my-auto max-w-screen-md">
                      <h2 className="mb-4 text-7xl tracking-tight  text-center text-indigo-600 dark:text-white">GOT TICKETS
                          OR CLOTHING TO SELL?</h2>
                      <p className="mb-8 lg:mb-16 font-light text-center text-white dark:text-gray-400 sm:text-xl">Contact
                          us below.</p>

                      <Form className="space-y-8">
                          <div>
                              <label htmlFor="email" className="block mb-2 text-sm font-medium text-white dark:text-gray-300">Your
                                  email
                              </label>
                              <Field
                                  type={"email"}
                                  id={"email"}
                                  name={"email"}
                                  className={"shadow-sm bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"}
                                  placeholder={"name@email.com"}
                                  required
                              />
                          </div>
                          <div className="sm:col-span-2">
                              <label htmlFor="message" className="block mb-2 text-sm font-medium text-white dark:text-gray-400">Your
                                  message
                              </label>
                              <Field
                                  as={"textarea"}
                                  id={"message"}
                                  name={"message"}
                                  rows={6}
                                  className="block p-2.5 w-full text-sm text-black bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                  placeholder={"Leave a message..."}
                              />
                          </div>
                          <button type="submit"
                                  disabled={isSubmitting}
                                  className="px-5 text-sm font-medium text-center text-white rounded-lg bg-indigo-600 sm:w-fit hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Send message
                          </button>
                      </Form>
                  </div>
              </section>
          )}
        </Formik>
  )
}

function mapStateToProps(state){
    return {};
}

const mapDispatchToProps = {
    contactFormThunk: actions.contactFormThunk
}

export default connect(mapStateToProps, mapDispatchToProps)(Interested);


