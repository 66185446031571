import React from 'react'

const Navbar = () => {
  return (
    <div className='sticky top-0 z-50 '>
       <nav class="bg-black border-gray-200 dark:bg-gray-900 flex items-center justify-center">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
    <a href="/" class="flex items-center">

        <span class="flex items-center justify-center text-2xl font-semibold whitespace-nowrap text-white">FLY |</span>
           <a href='https://mailchi.mp/flysenga/register-early-access'>
           <h1 className=' text-indigo-600  text-2xl px-2 py-3 font-semibold  h-8 flex items-center justify-center'>
           Register Now
           </h1>
</a>  
    </a>
  </div>
</nav>
    </div>
  )
}

export default Navbar