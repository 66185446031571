import {createSlice} from "@reduxjs/toolkit";

const contactFormState = {
    email: "",
    message: ""
};

const contactSlice = createSlice({
    name: "contact",
    initialState: contactFormState,
    reducers: {
        submitQuery(state, action){
            const {email, message} = action.payload;
            state.email = email;
            state.message = message;
        }
    }
});

export const {submitQuery} = contactSlice.actions;

export default contactSlice.reducer;