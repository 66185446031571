import axios from "axios";


export const contactFormThunk = () => async (dispatch, getState) => {

    const state = getState();
    const {contact} = state;

    axios({
        method: 'post',
        url: "https://flysengaserver.com/contact",
        data: contact
    }).then((response) => {
        if (response.status === 201){
        }
    }).catch((err) => {
    });

}